body {
  background-color: rgb(243, 243, 243);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* .container {
  display: flex;
  height: 100vh;
  margin: 0px;
} */

.app-container {
  position: relative;
  height: 100vh;
}

.navbar {
  background-color: white;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  border-bottom: 1px solid lightgray;
}

.navbar-title {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.map-container {
  flex: 1;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.sidebar-header {
  position: absolute;
  font-weight: 500;
  left: 0;
  padding: 0px 5px 0px 5px;
  flex-shrink: 0;
  background-color: #fff;
  color: black;
  z-index: 2;
  border-right: 1px solid lightgray;
}

.sidebar-body {
  font-weight: 500;
  left: 0;
  height: 100%;
  flex-shrink: 0;
  background-color: #fff;
  color: black;
  z-index: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 1px solid lightgray;
}

.sidebar-footer {
  position: absolute;
  font-weight: 500;
  /* top: 0; */
  left: 0;
  /* bottom: 0; */
  flex-shrink: 0;
  background-color: #fff;
  color: black;
  z-index: 2;
  /* border-right: 1px solid lightgray; */
  /* border-top: 1px solid lightgray; */
  border-bottom: 1px solid gray;
}

.sidebar-item {
  padding: .75em 1em;
  display: block;
  transition: background-color .15s;
}

.sidebar-item:hover {
  background-color: rgba(255, 255, 255, .1);
}

.sidebar-title {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
}

.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
}

.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform .3s;
}

.sidebar-item.open>.sidebar-title .toggle-btn {
  transform: rotate(180deg);
}

.sidebar-item.open>.sidebar-title {
  background-color: #e7f1ff;
  color: #5e84c3;
  margin: -12px -30px -5px -30px;
  padding: 12px 30px 12px 30px;
}

.sidebar-content {
  padding-top: .25em;
  height: 0;
  overflow: hidden;
}

.sidebar-item.open>.sidebar-content {
  height: auto;
}

.sidebar-item.plain {
  color: #070000;
  text-decoration: none;
  font-size: 1.1em;
  font-size: 16px;
}

.sidebar-item.smallFont {
  color: #070000;
  text-decoration: none;
  font-size: 1.1em;
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 0px;
}

.sidebar-item.plain:hover {
  text-decoration: underline;
}

.sidebar-item.plain i {
  display: inline-block;
  width: 1.7em;
}

.custom-icon {
  display: inline-block;
  /* Display as inline block */
  width: 32px;
  /* Set width */
  height: 32px;
  /* Set height */
  vertical-align: middle;
  /* Vertically align within the container */
  margin-right: 8px;
}