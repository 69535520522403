.info-panel-header {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 11px;
    line-height: 2;
    /* color: #6b6b76; */
    text-transform: uppercase;
    outline: none;
    /* margin-top: 65px; */
    z-index: 2;
    background-color: #fff;
    /* height: 100vh; */
    border-left: 1px solid lightgray;
}

.info-panel-body {
    position: absolute;
    top: 0px;
    right: 0;
    padding: 66px 1px 0px 1px;
    font-size: 12px;
    line-height: 2;
    /* color: #6b6b76; */
    /* text-transform: uppercase; */
    outline: none;
    /* margin-top: 65px; */
    z-index: 1;
    background-color: #fff;
    height: 100vh;
    border-left: 1px solid lightgray;
    overflow-y: scroll;
    overflow-x: hidden;
}

.info-content {
    padding: 40px 12px 12px 12px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
}

/* .info-header {
    display: flex;
    width: 100%;
    justify-content: end;
} */

.info-header {
    display: flex;
    width: 100%;
    padding: 18px 12px 16px 12px;
    justify-content: space-between;
}

button.info-expand {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    font-size: small;
    height: fit-content;
    width: fit-content;
}

.attribute {
    display: flex;
    align-items: center;
}

.panel-icons {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: -7px;
}